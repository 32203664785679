import React, { FC } from 'react'
import { Heading } from './primitives'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { RouteDropdown } from './RouteDropdown'
import HomeBanner from '../img/home-banner.svg'
import { breakpoints } from '../helpers'

type Props = {}

const RouteBanner: FC<Props> = () => {
	const routes = [
		{
			name: 'Renew my policy',
			path: '/renew-your-policy',
		},
		{
			name: 'Report a loss',
			path: '/report-a-loss',
		},
		{
			name: 'Get a quote',
			path: '/get-a-quote',
		},
	]

	return (
		<Wrapper>
			<Content>
				<Row style={{ position: 'relative', zIndex: 1 }}>
					<Col xs={12} md={8} mdOffset={1} lg={7}>
						<Heading variant={'h1'} as={'h1'}>
							How can we help you today?
						</Heading>
						<Subheading>
							We’re here to make dealing with <span>insurance</span> simple, easy and efficient by connecting you with the best insurers and intermediaries on the island.
						</Subheading>
						<RouteDropdown routes={routes} />
					</Col>
				</Row>
				<ImageWrapper>
					<Image />
				</ImageWrapper>
			</Content>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	overflow: hidden;
	padding-top: 3rem;

	${breakpoints.desktop} {
		min-height: 1000px;
		padding-top: 5rem;
	}
`

const Subheading = styled.div`
	color: ${(props) => props.theme.secondary};
	font-family: ${(props) => props.theme.fonts.montserrat};
	font-size: 24px;
	font-weight: bold;

	span {
		color: ${(props) => props.theme.primary};
	}
`

const Content = styled(Grid)`
	position: relative;
`

const ImageWrapper = styled.div`
	${breakpoints.mobile} {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	${breakpoints.desktop} {
		position: absolute;
		top: 0;
		right: 2rem;
	}
`

const Image = styled(HomeBanner)`
	${breakpoints.mobile} {
		width: 100%;
		height: auto;
	}
`

export { RouteBanner }
