import React, { ChangeEvent, Component, FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import Dropdown, { Option } from 'react-dropdown'
import ChevronUp from '../img/chevron-up.svg'
import ChevronDown from '../img/chevron-down.svg'
import { SelectWrapper } from './primitives/forms'
import { navigate } from 'gatsby'
import { breakpoints, pushToDataLayer } from '../helpers'

export type Route = { name: string; path: string }

type Props = {
	routes: Route[]
}

const RouteDropdown: FC<Props> = ({ routes }) => {
	const options = routes.map(({ name, path }) => ({ label: name, value: path }))
	const [option, setOption] = useState<Option>(options[0])

	useEffect(() => {
		pushToDataLayer('go', option.value)
	}, [option])

	const onChange = (arg: Option) => {
		pushToDataLayer('go', arg.value)
		setOption(arg)
	}

	const onGo = async () => {
		await navigate(option.value)
	}

	return (
		<Wrapper>
			<Label>I would like to:</Label>
			<Control>
				<StSelectWrapper>
					<Dropdown
						onChange={onChange}
						options={options}
						value={option}
						arrowOpen={<ChevronUp />}
						arrowClosed={<ChevronDown />}
					/>
				</StSelectWrapper>
				<Go onClick={onGo} id='gtm-home-go'>
					go
				</Go>
			</Control>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	padding: 3rem 0;

	${breakpoints.desktop} {
		align-items: flex-start;
	}
`

const Label = styled.div`
	background: ${theme.secondary};
	color: white;
	font-weight: bold;
	border-radius: 10px 10px 0 0;
	padding: 0.5rem 2rem;

	${breakpoints.mobile} {
		text-align: center;
		padding: 0.5rem 1rem;
	}
`

const Control = styled.div`
	display: flex;
`

const StSelectWrapper = styled(SelectWrapper)`
	${breakpoints.mobile} {
		flex-grow: 2;
	}

	${breakpoints.mobile} {
		width: 100%;
	}

	${breakpoints.desktop} {
		min-width: 300px;
	}

	.Dropdown-control {
		border: 2px solid ${theme.primary};
		border-radius: 0 0 0 10px;
		transition: none;

		${breakpoints.mobile} {
			padding: 1rem 1rem 1rem 1rem;
		}
	}

	.is-open .Dropdown-control {
		border-radius: 0;
	}

	.Dropdown-placeholder {
		font-weight: bold;
		font-family: ${theme.fonts.montserrat};
		color: ${theme.primary};
	}

	.Dropdown-menu {
		border: 2px solid ${theme.primary};
		margin-top: -2px;
	}

	.Dropdown-option {
		font-weight: bold;
		font-family: ${theme.fonts.montserrat};
		color: ${theme.primary};
	}

	.Dropdown-option + .Dropdown-option {
		border-top: 2px solid ${theme.primary};
	}
`

const Go = styled.button`
	background: ${theme.primary};
	color: white;
	font-weight: bold;
	font-family: ${theme.fonts.montserrat};
	padding: 1rem 2rem;
	font-size: 1rem;
	text-transform: uppercase;
	border: 0;
	border-radius: 0 10px 10px 0;
	cursor: pointer;
	margin: 0;

	${breakpoints.mobile} {
		padding: 0 0.8rem;
		border-radius: 0 0 10px 0;
	}
`

export { RouteDropdown }
