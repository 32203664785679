import React from 'react'
import { Button, Heading, Layout, TextBanner, TextImage } from '../components'
import { graphql, Link } from 'gatsby'
import { RouteBanner } from '../components/RouteBanner'
import TimeIcon from '../img/time.svg'

type IndexPageTemplateProps = {
	title: string
}

const IndexPageTemplate: React.FC<IndexPageTemplateProps> = ({ title }) => {
	return (
		<>
			<RouteBanner />
			<TextBanner icon={<TimeIcon />}>
				We’re giving you back some <span>time</span> in your day, to do the things that matter most.
			</TextBanner>
			<TextImage image={{ title: 'text', src: '/img/city.jpg', alt: 'Desk with lamp and clock' }}>
				<Heading variant={'h3'} as={'h2'}>
					<Link to={'/about-us'}>About Us</Link>
				</Heading>
				<p>
					Time is the one thing that we cannot get back. It is with this in mind that we designed our services to assist
					you with making the most of your precious time. Skip the line and in person wait time and do more of what you
					really want to do. We have created a platform through which you can easily access some of the best insurance
					intermediaries in the British Virgin Islands. Worry no more about traffic, finding parking or having to use
					most of your lunch hour to handle insurance matters. Let your fingers do the walking from the convenience of
					wherever you are, and in less than 10 mins you're done.
				</p>
				<Button as={Link} to={'/about-us'} variant={'secondary'}>
					Read more
				</Button>
			</TextImage>
		</>
	)
}

type IndexPageProps = {
	data: {
		markdownRemark: {
			frontmatter: IndexPageTemplateProps
		}
	}
}

const IndexPage: React.FC<IndexPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout showRenewLinkOnMobile showFooterContact padMain={false}>
			<IndexPageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default IndexPage
export { pageQuery, IndexPageTemplate }
